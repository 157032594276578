import React, { useEffect, useState } from 'react'
import { MdArrowBackIosNew } from 'react-icons/md'
import { FaPlus } from "react-icons/fa";
import { Field, Form, Formik } from 'formik';
import Input from '../Input';
import { UploadImage } from '../uplaodImage';
import { TiDelete } from "react-icons/ti";
import { createVoucherValidator } from '../../utils/validators';
import axios from "../../axios"
import { toast } from 'react-toastify';
import Overlay from '../Overlay';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { RiEditFill } from 'react-icons/ri';
export default function ViewVoucher() {

  let { id } = useParams();

 

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory()
  const [voucherValue, setVoucherValue] = useState({
    brandIcon: "",
    brandName: "",
    voucherCode: "",
    quantity: "",
    expireDate: "",
    expireTime: "",
    description: ""
  })
  const [brandImg, setBrandImg] = useState("")
  const uploadfile = async (e, formikBag) => {
    console.log(e);
    const file = e.target.files[0];
    const url = await UploadImage(file);
    console.log(url);
    if (url && url.data && url.data.image_url) {
      formikBag.setFieldValue("brandIcon", url.data.image_url);
      setBrandImg(url.data.image_url)
    } else {
      console.error("Failed to upload or get image URL.");
    }
  };
  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await axios.get(`private/getReward/${id}`);
      setVoucherValue(data?.data?.data?.vouchers);
      setBrandImg(data?.data?.data?.vouchers?.brandIcon)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])


  return (
    <div className="hewe-cont">
      <div className="referralcont">
        <div className="flex-align-center justify-content-between">
          <div className='flex-align-center'>
            <div className="me-2"><MdArrowBackIosNew style={{ fontSize: "30px" }} onClick={() => history.push("/voucher")} /></div>
            <h2 className="font-dark-green mb-0">View Voucher</h2>
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={voucherValue}
          validate={createVoucherValidator}
          validateOnChange
        //   onSubmit={(values) => handleCreateVoucher(values)}
        >
          {(formikBag) => {
            { console.log(formikBag) }
            return (
              <Form className='vocherform'>
                <div className="row">
                  <div className="col-3">
                    <div className="imgcont">
                      <input
                        type="file"
                        id="avatar-upload"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => uploadfile(e, formikBag)}
                      />
                      {
                        brandImg != "" ?
                          <img src={brandImg} alt="img" className='brandimg' />
                          : <FaPlus className='plusIcon' onClick={() => document.getElementById("avatar-upload").click()} />
                      }
                     
                    </div>
                  </div>
                  <div className='col-9'>
                    <div className="row ">
                      <div className="col-4 mb-4 mb-4">
                        <label htmlFor="">Brand Name</label>
                        <Field name="brandName" className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.brandName}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "brandName",
                                  e.target.value
                                );
                              }}
                             
                              className="form-control"
                              placeholder="Enter Brand Name"
                              readOnly

                            />
                          )}
                        </Field>

                      </div>
                      <div className="col-4 mb-4">
                        <label htmlFor="">Voucher Code</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.voucherCode}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "voucherCode",
                                  e.target.value
                                );
                              }}
                             
                              className="form-control"
                              placeholder="Enter Voucher Code"
                              readOnly
                              
                            />
                          )}
                        </Field>

                      </div>
                      <div className="col-2 mb-4">
                        <label htmlFor="" placeholder='Enter Quantity'>Quantity</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="number"
                              value={formikBag.values.quantity}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "quantity",
                                  e.target.value
                                );
                              }}
                              readOnly
                            
                              className="form-control"
                            />
                          )}
                        </Field>

                      </div>
                      <div className="col-4 mb-4">
                        <label htmlFor="" placeholder='Select Date' >Expiry Date</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="date"
                              value={formikBag.values.expireDate}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "expireDate",
                                  e.target.value
                                );
                              }}
                              readOnly
                              className="form-control"
                            />
                          )}
                        </Field>

                      </div>
                      <div className="col-4 mb-4">
                        <label htmlFor="">Expiry Time</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="time"
                              value={formikBag.values.expireTime}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "expireTime",
                                  e.target.value
                                );
                              }}
                              readOnly
                              
                              className="form-control"
                            />
                          )}
                        </Field>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <label htmlFor="">How to Use</label>
                    <div className="inptCont">
                      <textarea name="" id="" value={formikBag.values.description} placeholder='type here....' onChange={(e) => formikBag.setFieldValue("description", e.target.value)} readOnly></textarea>
                    </div>
                  </div>
                </div>
                
              </Form>
            )
          }}
        </Formik>
      </div>
      {isLoading && <Overlay />}
    </div>

  )
}
