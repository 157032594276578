import React, { useState } from 'react'
import { MdArrowBackIosNew } from 'react-icons/md'
import { FaPlus } from "react-icons/fa";
import { Field, Form, Formik } from 'formik';
import Input from '../Input';
import { UploadImage } from '../uplaodImage';
import { TiDelete } from "react-icons/ti";
import { createVoucherValidator } from '../../utils/validators';
import axios from "../../axios"
import { toast } from 'react-toastify';
import Overlay from '../Overlay';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RiEditFill } from 'react-icons/ri';
export default function CreateVoucher() {
  const [isLoading, setIsLoading] = useState(false);
  const history=useHistory()
  const [voucherValue, setVoucherValue] = useState({
    brandIcon: "",
    brandName: "",
    voucherCode: "",
    quantity: "",
    expireDate: "",
    expireTime: "",
    description: ""
  })
  const [brandImg, setBrandImg] = useState("")
  const uploadfile = async (e, formikBag) => {
    console.log(e);
    const file = e.target.files[0];
    const url = await UploadImage(file);
    console.log(url);
    if (url && url.data && url.data.image_url) {
      formikBag.setFieldValue("brandIcon", url.data.image_url);
      setBrandImg(url.data.image_url)
    } else {
      console.error("Failed to upload or get image URL.");
    }
  };
  const handleCreateVoucher = async (value) => {
    setIsLoading(true)
    try {
      let formData = {
        categoryName: "1",
        vouchers: {
          brandIcon: value.brandIcon,
          brandName: value.brandName,
          voucherCode: value.voucherCode,
          quantity: Number(value.quantity),
          expireDate: value.expireDate,
          expireTime: value.expireTime,
          description: value.description
        }
      };
      const data = await axios.post("/private/createVoucherReward", formData);
      toast.success(data?.data?.message);
      history.push("/voucher")
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(error.response.data?.errors[0]?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <div className="hewe-cont">
      <div className="referralcont">
        <div className="flex-align-center justify-content-between">
          <div className='flex-align-center'>
            <div className="me-2"><MdArrowBackIosNew style={{ fontSize: "30px" }} onClick={()=>history.push("/voucher")} /></div>
            <h2 className="font-dark-green mb-0">Add Voucher</h2>
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={voucherValue}
          validate={(values)=> createVoucherValidator(values)}
          validateOnChange
          onSubmit={(values) => handleCreateVoucher(values)}
        >
          {(formikBag) => {
            { console.log(formikBag) }
            return (
              <Form className='vocherform'>
                <div className="row">
                  <div className="col-3">
                    <div className="imgcont">
                      <input
                        type="file"
                        id="avatar-upload"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => uploadfile(e, formikBag)}
                      />
                      {
                        brandImg != "" ?
                          <img src={brandImg} alt="img" className='brandimg' />
                          : <FaPlus className='plusIcon' onClick={() => document.getElementById("avatar-upload").click()} />
                      }
                      {
                        brandImg != "" ?
                          <RiEditFill className='edit' onClick={() => document.getElementById("avatar-upload").click()} />
                          : null
                      }
                    </div>
                    <p className='error'>{
                                formikBag.touched.brandIcon &&
                                  formikBag.errors.brandIcon
                                  ? formikBag.errors.brandIcon
                                  : null
                              }</p>
                  </div>
                  <div className='col-9'>
                    <div className="row ">
                      <div className="col-4 mb-4 mb-4">
                        <label htmlFor="">Brand Name</label>
                        <Field name="brandName" className="inptCont">
                          {({ field}) => (
                            <Input
                              {...field}
                              type="text"
                              name="brandName"
                              value={formikBag.values.brandName}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "brandName",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.brandName &&
                                  formikBag.errors.brandName
                                  ? formikBag.errors.brandName
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter Brand Name"
                            /> 
                          )}
                        </Field>

                      </div>
                      <div className="col-4 mb-4">
                        <label htmlFor="">Voucher Code</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.voucherCode}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "voucherCode",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.voucherCode &&
                                  formikBag.errors.voucherCode
                                  ? formikBag.errors.voucherCode
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter Voucher Code"
                            />
                            
                          )}
                        </Field>
                       
                      </div>
                      <div className="col-2 mb-4">
                        <label htmlFor="" placeholder='Enter Quantity'>Quantity</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="number"
                              value={formikBag.values.quantity}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "quantity",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.quantity &&
                                  formikBag.errors.quantity
                                  ? formikBag.errors.quantity
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>

                      </div>
                      <div className="col-4 mb-4">
                        <label htmlFor="" placeholder='Select Date' >Expiry Date</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="date"
                              value={formikBag.values.expireDate}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "expireDate",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.expireDate &&
                                  formikBag.errors.expireDate
                                  ? formikBag.errors.expireDate
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>

                      </div>
                      <div className="col-4 mb-4">
                        <label htmlFor="">Expiry Time</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="time"
                              value={formikBag.values.expireTime}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "expireTime",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.expireTime &&
                                  formikBag.errors.expireTime
                                  ? formikBag.errors.expireTime
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <label htmlFor="">How to Use</label>
                    <div className="inptCont">
                      <textarea name="" id="" placeholder='type here....' onChange={(e) => formikBag.setFieldValue("description", e.target.value)}></textarea>
                      <p className='error'>{
                                formikBag.touched.description &&
                                  formikBag.errors.description
                                  ? formikBag.errors.description
                                  : null
                              }</p>
                    </div>
                  </div>
                </div>
                <div>
                  <button type="submit" className='savebtn' >Save</button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      {isLoading && <Overlay />}
    </div>
    
  )
}
