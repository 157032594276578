import { dictionaryList } from "./language/index";
const defaultLanguage = "en";
const messages = dictionaryList[defaultLanguage].errors;

// Global regex
const noHtmlRegex = /<\/?[^>]+(>|$)/g;
const onlyAlphbetRegex = /^[a-zA-Z ]*$/;
const numberOnly = /^\d+$/;
var phoneRegex = /^[0-9]+$/;
var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,12}$/;
const unitRegex = /^[0-9]+\.?[0-9]*$/;

const checkEmail = (value) => {
  if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return true;
  } else if (
    value.includes('"') ||
    value.includes("'") ||
    value.includes(",") ||
    value.includes(" ")
  ) {
    return true;
  } else {
    return false;
  }
};

export const loginValidator = (values) => {
  let errors = {};
  console.log(values);

  if (!values.email) {
    errors.email = "Please Enter your email";
  } else if (checkEmail(values.email)) {
    errors.email = messages.email;
  }

  if (!values.password) {
    errors.password = "Please enter your password";
  }
  return errors;
};

export const forgotPassword = (values) => {
  let errors = {};
  console.log(values);

  if (!values.email) {
    errors.email = "Please Enter your email";
  } else if (checkEmail(values.email)) {
    errors.email = messages.email;
  }

  return errors;
};

export const resetPassword = (values) => {
  let errors = {};

  if (!values.new_password) {
    errors.new_password = "Please Enter new password";
  } else if (!passwordRegex.test(values.new_password)) {
    errors.new_password = messages.password;
  }

  if (!values.confirm_password) {
    errors.confirm_password = "Please Enter confirm password";
  } else if (values.new_password !== values.confirm_password) {
    errors.confirm_password = messages.passwordMatch;
  }

  return errors;
};

export const changedPasswordValidator = (values) => {
  let errors = {};
  console.log(values);

  if (!values.oldPassword) {
    errors.oldPassword = "Please enter your old password";
  }

  if (!values.newPassword) {
    errors.newPassword = "Please enter your new password";
  } else if (!passwordRegex.test(values.newPassword)) {
    errors.newPassword = messages.password;
  }

  if (!values.confirm_password) {
    errors.confirm_password = "Please re-enter same password";
  } else if (values.newPassword !== values.confirm_password) {
    errors.confirm_password = messages.passwordMatch;
  }

  return errors;
};

export const forgetValidator = (values) => {
  let errors = {};
  if (!values.mobile_number) {
    errors.phone = messages.invalid;
  } else if (!phoneRegex.test(values.mobile_number)) {
    errors.phone = messages.phone;
  } else if (values.mobile_number.length < 4) {
    errors.phone = messages.phone;
  }

  return errors;
};



export const completeProfileValidator = (values) => {
  let errors = {};
  // console.log(values);
  if (!values.username) {
    errors.username = messages.invalid;
  }
  if (!values.mobile_number) {
    errors.mobile_number = messages.invalid;
  } else if (!phoneRegex.test(values.mobile_number)) {
    errors.mobile_number = messages.phone;
  } else if (values.mobile_number.length < 4) {
    errors.mobile_number = messages.phone;
  }
  if (!values.email) {
    errors.email = messages.invalid;
  }
  if (!values.profile_image[0]) {
    errors.profile_image = "Upload profile image";
  }
  return errors;
};


export const createVoucherValidator=(values)=>{
  console.log(values,"--->>")
  let errors = {};
  if (!values.brandIcon) {
    errors.brandIcon = "Please Upload brand icon";
  } 
  if (!values.brandName) {
    errors.brandName = "Please enter brand name";
  } 
  if (!values.voucherCode) {
    errors.voucherCode = "Please enter vouchers code";
  } 
  if (!values.quantity) {
    errors.quantity = "Please enter quantity";
  } 
  if (!values.expireDate) {
    errors.expireDate = "Please select expiry date";
  } 
  if (!values.expireTime) {
    errors.expireTime = "Please select expiry time";
  } 
  if (!values.description) {
    errors.description = "Please enter description";
  } 
  return errors; 
}