import React, { useState, useEffect } from "react";
import { MdEmail, MdPhone } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import {
  DashboardContainer,
  DashboardWrapper,
  DashboardHeading,
  DashHeading,
  MenuAndBack,
  ProfileDetailForm,
  InputDivide,
} from "./ProfileElements";
import { LoginButton } from "./ProfileElements";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Field, Form } from "formik";
import InputLogin from "../../../components/InputLogin";
import axios from "../../../axios";
import Axios from "axios";
import Overlay from "../../../components/Overlay";
import Input from "../../../components/Input";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";
import { FaCamera } from "react-icons/fa";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import * as actionTypes from "../../../store/actions";
import { withRouter, Link, useHistory } from "react-router-dom";
import { passwordProfileValidator } from "../../../utils/validators";
import Avatar from "@mui/material/Avatar";
import dummyProfile from "../../../assets/images/lady.png";
import "./profile.css";
// import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

import PhoneInput from "react-phone-input-2";
const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
  tablePadding: {
    padding: "0.5rem",
    textAlign: "center",
    fontSize: "0.8rem",
  },
  paperTableHeight: {
    width: "95%",
    marginLeft: "2rem",
  },
  "@media (max-width: 780px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  "@media (max-width: 480px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  tablePaginationStyle: {
    border: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  tableFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ProfileManagement = ({ setUsers, userData }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = localStorage.getItem("token") || sessionStorage.getItem("token");
  const [profileImage, setProfileImage] = useState("");

  const [profileUpdate, setProfileUpdate] = useState({
    firstName: "",
    lastName: "",
    countryCode: "",
    phoneNumber: "",
    email: "",
  });

  console.log(profileUpdate)

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    setIsLoading(true);

    try {
      const { data } = await axios.get(`/private/getProfile`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setProfileUpdate(data.data);
      setPhoneData({
        countryCodeName:data.data.countryCodeName || "",
        countryCode: data.data.countryCode || ""
      });
      setProfileImage(data.data.profileImage);
      setUsers(data.data);
      localStorage.setItem("userData", JSON.stringify(data.data)) || sessionStorage.setItem("userData", JSON.stringify(data.data));
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(`${error.response.data?.error}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };


  const [phoneData, setPhoneData] = useState({
    countryCodeName:"",
    countryCode: "",
  });
  
  console.log(phoneData)
  const handlePhoneChange = (value, data) => {
    console.log(data)
    const phoneNumber = value.slice(data.dialCode.length);
    setPhoneData({
      countryCodeName:data.countryCode,
      countryCode: `+${data.dialCode}`,
    });
  };

  const handleUserProfile = async (values) => {
    setIsLoading(true);
    console.log(values.phoneNumber ,typeof values.phoneNumber);
    var fromData = {
      firstName: values.firstName,
      lastName: values.lastName,
      countryCode: phoneData.countryCode,
      countryCodeName:phoneData.countryCodeName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      profileImage: profileImage,
    };

    try {
      const { data } = await axios.put(`/private/editProfile`, fromData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setProfileUpdate(data.data);
      setUsers(data.data);
      localStorage.setItem("userData", JSON.stringify(data.data)) || sessionStorage.setItem("userData", JSON.stringify(data.data));
      getUserData()
      toast.success(`${data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getUserData();
    } catch (error) {
      if (error.response.status == 422) {
        toast.error(error.response.data?.errors[0]?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfileImage = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    setIsLoading(true);

    try {
      const { data } = await Axios.post(
        "https://game.hewe.club/fileUpload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setProfileImage(data?.data?.image_url);
      toast.success(data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error("Failed to update profile image", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      <DashboardContainer>
        <DashboardWrapper>
          <DashboardHeading>
            <MenuAndBack>
              {/* <div
                className="left-arrow"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Cookies.set("showProfileSidebar", false, { expires: 365 });
                  history.push({
                    pathname: "/dashboard",
                  });
                }}
              >
                <FaArrowLeft style={{ fontSize: "1.2rem", color: "#264503" }} />
              </div> */}
              <DashHeading>My Profile</DashHeading>
            </MenuAndBack>
          </DashboardHeading>

          <Paper className={classes.paperTableHeight}>
            <ProfileDetailForm>
              <Formik
                enableReinitialize
                initialValues={profileUpdate}
                // validate={passwordProfileValidator}
                // validateOnChange
                onSubmit={(values) => handleUserProfile(values)}
              >
                {(formikBag) => {
                  return (
                    <Form className="profile-form">
                      <div className="d-flex align-items-center">
                        <div className="profile-show me-4">
                          <input
                            type="file"
                            id="avatar-upload"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => handleProfileImage(e)}
                          />
                          <Avatar
                            alt="profile"
                            src={profileImage ? profileImage : dummyProfile}
                            sx={{ width: 200, height: 200 }}
                          />
                        </div>
                        <div
                          className="btn1 me-4"
                          onClick={() =>
                            document.getElementById("avatar-upload").click()
                          }
                        >
                          Upload new
                        </div>
                        {
                          profileUpdate?.profileImage!="" ?<div
                          className="btn1"
                          onClick={() => setProfileImage("")}
                        >
                          Remove Profile Picture
                        </div> :null
                        }
                        
                      </div>
                      <div>
                        <InputDivide>
                          <div className="col-md-6" style={{ padding: "1rem" }}>
                            <label>First Name</label>
                            <div className="form-controlcont">
                              <FaUserAlt
                                style={{ fontSize: "18px", color: "#88AE40" }}
                              />
                              <Field name="firstName w-100">
                                {({ field }) => (
                                  <Input
                                    {...field}
                                    type="text"
                                    value={formikBag.values.firstName}
                                    onChange={(e) => {
                                      formikBag.setFieldValue(
                                        "firstName",
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      formikBag.touched.firstName &&
                                      formikBag.errors.firstName
                                        ? formikBag.errors.firstName
                                        : null
                                    }
                                    className="form-control"
                                    placeholder="Enter First Name"
                                    minLength="3"
                                    maxlength="12"
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-md-6" style={{ padding: "1rem" }}>
                            <label>Last Name</label>
                            <div className="form-controlcont">
                              <FaUserAlt
                                style={{ fontSize: "18px", color: "#88AE40" }}
                              />
                              <Field name="lastName">
                                {({ field }) => (
                                  <Input
                                    {...field}
                                    type="text"
                                    value={formikBag.values.lastName}
                                    onChange={(e) => {
                                      formikBag.setFieldValue(
                                        "lastName",
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      formikBag.touched.lastName &&
                                      formikBag.errors.lastName
                                        ? formikBag.errors.lastName
                                        : null
                                    }
                                    className="form-control"
                                    placeholder="Enter Last Name"
                                    minLength="3"
                                    maxlength="12"
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </InputDivide>
                        <InputDivide className="col-md-12">
                          <div className="col-md-6" style={{ padding: "1rem" }}>
                            <label>Email</label>
                            <div className="form-controlcont">
                              <MdEmail
                                style={{ fontSize: "18px", color: "#88AE40" }}
                              />
                              <Field name="email">
                                {({ field }) => (
                                  <Input
                                    {...field}
                                    type="text"
                                    value={formikBag.values.email}
                                    onChange={(e) => {
                                      formikBag.setFieldValue(
                                        "email",
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      formikBag.touched.email &&
                                      formikBag.errors.email
                                        ? formikBag.errors.email
                                        : null
                                    }
                                    className="form-control"
                                    placeholder="Enter Your Email"
                                    readOnly={true}
                                    maxlength="30"
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-md-6" style={{ padding: "1rem" }}>
                            <label>Phone Number</label>
                            <div className="form-controlcont" >
                              <MdPhone
                                style={{ fontSize: "18px", color: "#88AE40" }}
                              />
                              <Field name="phoneNumber" >
                                {({ field }) => (
                                  <PhoneInput
                                    {...field}
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    className="phone-input"
                                    value={phoneData.countryCode}
                                    country={phoneData.countryCodeName}
                                    style={{width:"90px"}}
                                    onChange={handlePhoneChange}

                                  />
                                )}
                              </Field>
                              <Field name="phoneNumber">
                                {({ field }) => (
                                  <Input
                                    {...field}
                                    type="text"
                                    value={formikBag.values.phoneNumber}
                                    placeholder="Enter phone number"
                                    onChange={(e) => {
                                      formikBag.setFieldValue(
                                        "phoneNumber",
                                        e.target.value
                                      );
                                    }}
                                    style={{paddingLeft:"0px"}}
                                    error={
                                      formikBag.touched.phoneNumber &&
                                      formikBag.errors.phoneNumber
                                        ? formikBag.errors.phoneNumber
                                        : null
                                    }
                                    className="form-control"
                                    minLength="7"
                                    maxlength="15"
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </InputDivide>

                        <InputDivide>
                          <div className="" style={{ padding: "1rem" }}>
                            <LoginButton
                              type="submit"
                              className="buttonWidthResponsive"
                            >
                              Update Profile
                            </LoginButton>
                          </div>
                        </InputDivide>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </ProfileDetailForm>
          </Paper>
        </DashboardWrapper>
      </DashboardContainer>

      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileManagement));
