import React, { useEffect, useState } from 'react'
import { MdArrowBackIosNew, MdModeEdit } from 'react-icons/md'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { FaPlus, FaRegEdit, FaRegEye } from "react-icons/fa";
import axios from "../../axios"
import { toast } from 'react-toastify';
import Overlay from '../Overlay';
import "./style.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    makeStyles,
  } from "@material-ui/core";
import "./style.scss"
  const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#001050",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    button: {
        textAlign:"center",
        backgroundColor:"#88AE40",
        padding:"7px 30px",
        color:"white",
        width:"250px",
        border:"none",
        borderRadius:"4px"
    },
}));
export default function Voucher() {
  const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();

    const[tableData,setTableData]=useState();
    const history = useHistory();
    const handleRespose = async () => {
        setIsLoading(true)
        try {
            const data = await axios.get("/private/getRewardByCategory/1");
            console.log(data)
            setTableData(data?.data?.data);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.loog(error)
        }
    }
    useEffect(() => {
        handleRespose()
    }, [])

    const changeStatus=async(id,status)=>{
        setIsLoading(true)
        try {
            const formData={
                status:status
            }
            const data= await axios.put(`private/changeRewardStatus/${id}`,formData);
            toast.success(data?.data?.message)
            handleRespose()
            setIsLoading(false)

        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    console.log(tableData)
    return (
        <div className="hewe-cont">
            <div className="referralcont">
                <div className="flex-align-center justify-content-between">
                    <div className='flex-align-center'>
                        <div className="me-2"><MdArrowBackIosNew style={{ fontSize: "30px" }} onClick={()=>history.push("/other-reward")} /></div>
                        <h2 className="font-dark-green mb-0">Voucher</h2>
                    </div>
                    <div>
                        <button className='flex-align-center addbtn' onClick={() => history.push("/create-voucher")}><FaPlus className='me-2' /> Add Voucher</button>
                    </div>
                </div>

                <Paper className="mt-5">
            <>
                <TableContainer className={classes.tableMainContainer}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHeadingRow}>
                                <TableCell className={classes.tablseHeadingCell}>
                                    S.No
                                </TableCell>
                                <TableCell className={classes.tablseHeadingCell}>
                                    Voucher Name
                                </TableCell>
                                <TableCell className={[classes.tablseHeadingCell]}>
                                    Action
                                </TableCell>
                                <TableCell className={[classes.tablseHeadingCell]}>
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.textMiddle}>
                                        <div>{index + 1}</div>
                                    </TableCell>
                                    <TableCell className={classes.textMiddle}>
                                        <div>{item?.vouchers?.brandName   }</div>
                                    </TableCell>
                                    <TableCell className={classes.textMiddle}>
                                        <div>
                                        <MdModeEdit className='actionicon me-3'  onClick={()=>history.push(`/edit-voucher/${item?._id}`)} />
                                        <FaRegEye className='actionicon' onClick={()=>history.push(`/view-voucher/${item?._id}`)} />
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.textMiddle}>
                                        <div>{item?.vouchers?.status=="1"?<div className='activebg' onClick={()=>changeStatus(item?._id,item?.vouchers?.status)}>Active</div>:<div className='inactivebg' onClick={()=>changeStatus(item?._id,item?.vouchers?.status)}>InActive</div>}</div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        </Paper>
            </div>
      {isLoading && <Overlay />}
        </div>
    )
}
