import React, { useEffect, useState } from "react";
import axios from "../../axios";
import "./style.scss";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#001050",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    button: {
        textAlign:"center",
        backgroundColor:"#88AE40",
        padding:"7px 30px",
        color:"white",
        width:"250px",
        border:"none",
        borderRadius:"4px"
    },
}));
function OtherReward() {
    const history = useHistory()
    const classes = useStyles();
    const [senderValue, setSenderValue] = useState();
    const data = [
        {
            sno: "01",
            categoryName: "Vouchers",
            route: "voucher",
            button: "Manage Vouchers",
        },
        {
            sno: "02",
            categoryName: "In App Rewards",
            // route: "app-reward",
            button: "Manage In App Rewards",
        },
        {
            sno: "03",
            categoryName: "Audit Token",
            // route: "audit-token",
            button: "Audit Token",
        },
        {
            sno: "04",
            categoryName: "Premium Reward",
            // route: "premium-reward",
            button: "Manage Premium Reward",
        },
        {
            sno: "05",
            categoryName: "Trip Rewards",
            // route: "trip-reward",
            button: "Manage trip-reward",
        },
    ];

    return (
        <>
            <div className="hewe-cont">
                <div className="referralcont">
                    <div className="flex-align-center">
                        <div className="dot me-2"></div>
                        <h2 className="font-dark-green">Reward Category</h2>
                    </div>

                    <Paper className="mt-5">
                        <>
                            <TableContainer className={classes.tableMainContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Category Name
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item.sno}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item.categoryName}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                     <button className={classes.button} onClick={()=>history.push("/voucher")}>{item.button}</button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    </Paper>
                </div>
            </div>
        </>
    );
}

export default OtherReward;
