import axios from "axios";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa"; // Ensure FaPlus is imported

export const UploadImage = async (file) => {
  console.log(file);
  const formData = new FormData();
  formData.append("file", file);

  try {
    const { data } = await axios.post(
      "https://game.hewe.club/fileUpload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log(data);
    toast.success(data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return data;
  } catch (error) {
    toast.error("Failed to update profile image", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
